import React from 'react'
import Layout from '../components/Layout'
import MvTextPage from '../components/common/MvTextPage'
import MvTextPageSection from '../components/common/MvTextPageSection'
import MvTextPageHighlight from '../components/common/MvTextPageHighlight'

export default () => (
  <Layout>
    <MvTextPage title="Altpapiersammlung">
      <p className="text-center">
        Die nächste Sammlung findet am{' '}
        <strong>Samstag, den 12. Oktober 2024</strong> statt.
      </p>
      <MvTextPageHighlight
        title="Sammeln Sie Altpapier!"
        subtitle="Unterstützen Sie unsere Vereins- und Jugendarbeit"
        action="vorstand"
      >
        Mit ihrer Altpapierspende helfen Sie unserem Verein. Sie finanziert
        unsere Arbeit und stellt so eine wichtige Einkommensquelle für uns dar.
        Sollten Sie größere Mengen Altpapier spenden können oder nicht genug
        Platz zum Zwischenlagern haben, dann nehmen Sie gerne Kontakt mit uns
        auf und wir versuchen gemeinsam eine Lösung zu finden.
      </MvTextPageHighlight>

      <MvTextPageSection title="Wie Sie uns helfen können">
        <p>
          Sammeln Sie für uns ihre ausgelesenen Zeitungen, Werbeprospekte,
          Kataloge, Bücher oder andere Papierprodukte. Je mehr Gewicht, desto
          besser. Damit unsere fleißigen Helfer bei der Altpapiersammlung bis
          zum Ende durchhalten, bündeln Sie ihr Altpapier bitte in handlichen
          Päckchen.
        </p>
        <p>
          Ihr so gesammeltes Altpapier legen Sie am Tag der Sammlung bitte bis
          spätestens 8 Uhr gut sichtbar am Straßenrand bereit. Sollten Sie
          größere Mengen Altpapier gesammelt haben, können Sie gerne auch im
          Vorfeld Kontakt mit uns aufnehmen.
        </p>
        <p>
          Wir bedanken uns bereits jetzt für Ihren Einsatz und freuen uns auf
          gut gefüllte Container an unserer nächsten Altpapiersammlung.
        </p>
      </MvTextPageSection>
    </MvTextPage>
  </Layout>
)
